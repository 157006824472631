const translations = {
    en: {
      home: "Home",
      homeContent: "Welcome to Unggul Indonesia Maju Training Center! As the first SPPUR training institution in Bali, we are committed to helping you obtain certification promptly and creating competent human resources for a progressing Indonesia.",
      about: "About Us",
      aboutContent: `
        Unggul Indonesia Maju (UIM) Training Center is a job training institution established in 2022, located at Jl. By Pass Ir. Soekarno, Kediri, Tabanan – Bali. UIM is an expansion of PT. Tunjung Sari Kencana, which has been around since 2005.
        We operate in the field of Payment Systems and Rupiah Currency Management (SPPUR), specifically Subfield 5: Foreign Exchange Trading and Carrying Foreign Currency Notes. This training center was established based on Bank Indonesia Regulation Number 21/16/PBI/2019 on Competency Standards in SPPUR. According to this regulation, SPPUR operators must ensure their employees hold an SPPUR Certificate. Our goal is to assist SPPUR operators, especially in Foreign Exchange Trading and Carrying Foreign Currency Notes, so their employees are certified on time.
        We are the first SPPUR Training Center in Bali. We hope this institution can help certify money changer employees in Bali and throughout Indonesia. UIM is recognized by Bank Indonesia with number: 24/258/DPSP-GESK/Srt/B. We are also registered in the risk-based OSS with standard certificate number: 02860100217720003, verified by the Tabanan Regency Government – Bali.
        
        In all our work programs, whether short-term, medium-term, or long-term, we focus on our vision and mission through these Five Satisfactions. Client satisfaction is our main responsibility in the service and hospitality industry. 
        We see our company as a mother who spoils her children with love; that's how we treat our clients. Our motto is, "No child will turn away from their mother; no matter how far they go, they will return to her embrace." With this motto, we believe our clients will always remember the care and affection we provide and will return to us.
        Employee satisfaction is a crucial task for us in managing the company's assets. For us, employees are not machines; they are part of the company. We strive to create a work environment that may not be found elsewhere. We see employees as a mother's hands that lovingly caress her children. Hands are tools to give and embrace with love. That's why employees are an inseparable part of us.
      `,
      vision: "Vision",
      visionContent: `To be a professional and quality Training Center capable of producing competent human resources to support a progressing Indonesia.`,
      mission: "Mission",
      missionContent: ` To organize credible Competency-Based SPPUR Training and create competent human resources in the field of SPPUR. In fulfilling our commitment to customers and the community, we implement the Five Satisfactions, namely:
        1. Client/Customer/Partner Satisfaction
        2. Employee Satisfaction
        3. Owner Satisfaction
        4. Community Satisfaction
        5. Government Satisfaction
      `,
      contact: "Contact Us",
      contactContent: "Get in touch with us for any inquiries or feedback. You can reach us via email at pbk.unggulindonesiamaju@gmail.com or call us at 0361-461803 / 08123808866.",
    },
    id: {
      home: "Beranda",
      homeContent: "Selamat datang di LPK Unggul Indonesia Maju! Sebagai lembaga pelatihan SPPUR pertama di Bali, kami berkomitmen membantu Anda mendapatkan sertifikasi tepat waktu dan menciptakan SDM berkompeten untuk Indonesia maju.",
      about: "Tentang Kami",
      aboutContent: `
        LPK Unggul Indonesia Maju (UIM) adalah lembaga pelatihan kerja yang didirikan tahun 2022 dan berlokasi di Jl. By Pass Ir. Soekarno, Kediri, Tabanan – Bali. LPK UIM adalah pengembangan dari PT. Tunjung Sari Kencana yang sudah berdiri sejak 2005.
        Kami bergerak di bidang Sistem Pembayaran dan Pengelolaan Uang Rupiah (SPPUR), khususnya Sub Bidang 5: Penukaran Valuta Asing dan Pembawaan Uang Kertas Asing. LPK ini didirikan berdasarkan Peraturan Bank Indonesia Nomor 21/16/PBI/2019 tentang Standarisasi Kompetensi di Bidang SPPUR. Dalam aturan itu, pelaku SPPUR harus memastikan pegawainya memiliki Sertifikat SPPUR. Tujuan kami adalah membantu pelaku SPPUR, terutama di bidang Penukaran Valuta Asing dan Pembawaan Uang Kertas Asing, agar pegawai mereka tersertifikasi tepat waktu.
        Kami adalah Lembaga Pelatihan SPPUR pertama di Bali. Harapan kami, LPK ini bisa membantu mensertifikasi karyawan money changer di Bali dan seluruh Indonesia. LPK UIM sudah diakui oleh Bank Indonesia dengan nomor: 24/258/DPSP-GESK/Srt/B. Kami juga terdaftar di OSS berbasis risiko dengan nomor sertifikat standar: 02860100217720003 yang diverifikasi oleh Pemerintah Kabupaten Tabanan – Bali.
        
        Dalam setiap program kerja kami, baik jangka pendek, menengah, maupun panjang, kami fokus pada visi dan misi kami melalui Panca Kepuasan. Kepuasan klien/pelanggan/relasi adalah tanggung jawab utama kami di industri jasa dan keramahtamahan. 
        Kami menganggap perusahaan kami seperti ibu yang memanjakan anak-anaknya dengan kasih sayang; begitulah cara kami memperlakukan klien kami. Motto kami adalah, "Tidak ada anak yang akan berpaling dari ibunya; sejauh apapun pergi, ia akan kembali ke pangkuan ibu." Dengan motto ini, kami yakin klien kami akan selalu ingat dengan perhatian dan kasih sayang yang kami berikan dan akan kembali kepada kami.
        Kepuasan karyawan adalah tugas penting kami dalam mengelola aset perusahaan. Bagi kami, karyawan bukanlah mesin; mereka adalah bagian dari perusahaan. Kami berusaha menciptakan lingkungan kerja yang mungkin tidak ditemukan di tempat lain. Bagi kami, karyawan ibarat tangan seorang ibu yang dengan kasih membelai anak-anaknya. Tangan adalah alat untuk memberi dan merangkul dengan kasih sayang. Itulah sebabnya karyawan adalah bagian yang tak terpisahkan dari kami.
      `,
      vision: "Visi",
      visionContent: `Menjadi LPK yang profesional, berkualitas, dan mampu menghasilkan SDM berkompeten untuk mendukung Indonesia maju.`,
      mission: "Misi",
      missionContent: `Menyelenggarakan Pelatihan Berbasis Kompetensi SPPUR yang kredibel dan menciptakan SDM yang kompeten di bidang SPPUR. Dalam menjalankan komitmen kami kepada pelanggan dan masyarakat, kami menerapkan Panca Kepuasan, yaitu:
        1. Kepuasan Klien/Pelanggan/Relasi
        2. Kepuasan Karyawan
        3. Kepuasan Pemilik
        4. Kepuasan Masyarakat
        5. Kepuasan Pemerintah
      `,
      contact: "Kontak Kami",
      contactContent: "Hubungi kami untuk setiap pertanyaan atau masukan. Anda dapat menghubungi kami melalui email di pbk.unggulindonesiamaju@gmail.com atau telepon kami di 0361-461803 / 08123808866.",
    }
  };
  
  export default translations;