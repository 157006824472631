import React from 'react';

function Home({ t }) {
  return (
    <section id="home">
      <div className="content-wrapper">
        <h2>{t.home}</h2>
        <p>{t.homeContent}</p>
      </div>
    </section>
  );
}

export default Home;