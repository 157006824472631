import React from 'react';

function About({ t }) {
  return (
    <section id="about">
      <div className="content-wrapper">
        <h2>{t.about}</h2>
        <p>{t.aboutContent}</p>
        <h3>{t.vision}</h3>
        <p>{t.visionContent}</p>
        <h3>{t.mission}</h3>
        <p>{t.missionContent}</p>
      </div>
    </section>
  );
}

export default About;