import React from 'react';

function Contact({ t }) {
  return (
    <section id="contact">
      <div className="content-wrapper">
        <h2>{t.contact}</h2>
        <p>{t.contactContent}</p>
      </div>
    </section>
  );
}

export default Contact;