import React from 'react';

function Footer() {
  return (
    <footer>
      &copy; 2024 Unggul Indonesia Maju
    </footer>
  );
}

export default Footer;