import React, { useState, useRef } from 'react';
import translations from './translations/translations';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { Container } from 'react-bootstrap';
import './App.css';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [language, setLanguage] = useState("id");

  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  const handleScroll = (section) => {
    const sectionRef = {
      home: homeRef,
      about: aboutRef,
      contact: contactRef,
    }[section];
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const t = translations[language];

  return (
    <div className={darkMode ? "dark-mode" : "light-mode"}>
      <Header
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        language={language}
        setLanguage={setLanguage}
        handleScroll={handleScroll}
        t={t}
      />
      <Container className="mt-5">
        <div ref={homeRef}>
          <Home t={t} />
        </div>
        <div ref={aboutRef}>
          <About t={t} />
        </div>
        <div ref={contactRef}>
          <Contact t={t} />
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default App;