import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';

function Header({ darkMode, setDarkMode, language, setLanguage, handleScroll, t }) {
  const handleClick = (event, section) => {
    event.preventDefault();
    handleScroll(section);
  };

  return (
    <Navbar bg={darkMode ? 'dark' : 'light'} variant={darkMode ? 'dark' : 'light'} expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="#home">Unggul Indonesia Maju</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" onClick={(event) => handleClick(event, 'home')}>{t.home}</Nav.Link>
            <Nav.Link href="#about" onClick={(event) => handleClick(event, 'about')}>{t.about}</Nav.Link>
            <Nav.Link href="#contact" onClick={(event) => handleClick(event, 'contact')}>{t.contact}</Nav.Link>
          </Nav>
          <Button variant="outline-secondary" onClick={() => setDarkMode(!darkMode)}>
            <i className={darkMode ? "fas fa-sun" : "fas fa-moon"}></i>
          </Button>{' '}
          <Button variant="outline-secondary" onClick={() => setLanguage(language === "en" ? "id" : "en")}>
            {language === "en" ? "ID" : "EN"}
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;